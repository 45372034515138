import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "@parts/Layout";
import Hero from "@parts/Hero";
import Button from "@components/Button";
import "@parts/Layout/Layout.scss";

const DocumentationOverviewTemplate = ({
	pageContext: {
		slug,
		items: { edges: pages },
		categories: { edges: categories },
		isParent,
	},
	data: {
		wpPage: {
			ACF_PageTemplate: { flexContent: fields },
		},
	},
}) => {
	let filteredPages;

	if (isParent === false) {
		filteredPages = pages.filter((page) => page.node.documentationCategories.nodes[0].slug === slug);
	} else {
		filteredPages = pages;
	}

	return (
		<Layout>
			{isParent && <Hero id="hero-image-01" {...fields[0]} />}

			<Container>
				<Row className="page__content">
					<Col md={3}>
						<div className="submenu">
							<h3 className="submenu__title">
								<Link to="/resources/documentatie/">Categorieën</Link>
							</h3>
							<ul>
								{categories.map((category) => (
									<li key={category.node.id} className={category.node.slug === slug && "active"}>
										<Link to={`/resources/documentatie${category.node.uri}`}>
											{category.node.name}
										</Link>
									</li>
								))}
							</ul>
						</div>
					</Col>

					<Col md={9}>
						<Row className="list">
							{filteredPages.map((item) => (
								<Col md={6} xl={4} key={item.node.id} className="list__item">
									{item.node.featuredImage && (
										<Link
											to={`/resources/documentatie/${item.node.documentationCategories.nodes[0].slug}${item.node.uri}`}>
											<GatsbyImage
												image={item.node.featuredImage.node.localFile.childImageSharp?.gatsbyImageData}
												alt={item.node.featuredImage.node.altText}
											/>
										</Link>
									)}

									<div className="list__item__content">
										<Link
											to={`/resources/documentatie/${item.node.documentationCategories.nodes[0].slug}${item.node.uri}`}>
											<h3
												dangerouslySetInnerHTML={{
													__html: item.node.title,
												}}
											/>
										</Link>

										<div
											className="list__item__text"
											dangerouslySetInnerHTML={{
												__html: item.node.excerpt,
											}}
										/>

										<div className="list__item__button">
											<Button
												url={`/resources/documentatie/${item.node.documentationCategories.nodes[0].slug}${item.node.uri}`}
												title="Meer informatie"
												type="tertiary"
											/>
										</div>
									</div>
								</Col>
							))}
						</Row>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default DocumentationOverviewTemplate;

export const pageQuery = graphql`
	query {
		wpPage(databaseId: { eq: 206 }) {
			ACF_PageTemplate {
				flexContent {
					... on WpPage_AcfPagetemplate_FlexContent_HeroImage {
						title
						text
						button {
							target
							title
							url
						}
						tags {
							tag {
								target
								title
								url
							}
						}
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(placeholder: NONE)
								}
							}
						}
						subTitle1
						title1
						subTitle2
						title2
					}
				}
			}
		}
	}
`;
